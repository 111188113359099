
.home {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  background-color: rgb(248, 248, 248);
}

.top-img {
  z-index: -666;
  width: 100%;
  height: 350px;
}

.top-menu {
  width: 100%;
  background-color: #13549c;
  display: flex;
}

.content {
  width: 100%;
  display: flex;
  justify-content: center;
  background: linear-gradient(#386eaa 1%, #ffffff);
}

.bottom {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 220px;
  width: 100%;
  background: url('../assets/bottom.png');
  font-size: 10px;
  background-size: cover;
  .left {
    width: 300px;
    color: #ffffff;
    font-size: 5px;
    line-height: 30px;
    text-align: left;
  }

  .center-logo {
    width: 800px;
    height: 150px;
    margin: 0 60px;
    .logo {
      display: flex;
      justify-content: center;
      .logo-1 {
        img {
          height: 50px;
          width: 50px;
          background: #143f87;
          background-size: cover;
        }
      }
      .logo-2 {
        img {
          height: 50px;
          width: 220px;
          background: #143f87;
          background-size: cover;
        }
      }
    }

    .menu {
      display: flex;
      justify-content: center;
      font-size: 14px;
      margin-top: 10px;
      div {
        padding: 0 5px;
        color: #ffffff;
      }
    }

    .tip {
      margin-top: 25px;
      font-size: 12px;
      color: #ffffff;
    }
  }

  .right {
    color: #ffffff;
  }
}

.logo-group {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
  .logo-cr {
    margin-top: 30px;
    height: 80px;
    width: 80px;
  }
  .logo-font {
    height: 100px;
    width: 500px;
  }
}
.rectangle {
  .ant-carousel :deep(.slick-slide) {
    overflow: hidden;
  }

  .ant-carousel :deep(.slick-slide img) {
    width: 100%;
    height: 350px;
  }
}
.wechat {
  img {
    height: 70px;
    width: 70px;
    margin-bottom: 10px;
  }
}

.search-box {
  display: flex;
  margin-top: 10px;
  .icon-box {
    width: 30px;
    // height: 20px;
    background-color: #13549c;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    .ant-select-suffix {
      color: #ffffff;
    }
  }
  :deep(.ant-select:not(.ant-select-customize-input) .ant-select-selector) {
    opacity: 0.5;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
  // :deep(.ant-select-focused:not(.ant-select-tinyColor-ledisabled) .ant-select:not(.ant-select-customize-input) .ant-select-selector) {
  // 	border-color: #13549c!important;
  // 	box-shadow: 0 0 0 2px #13549c;
  // }
}
.float-link {
  position: fixed;
  width: 100px;
  right: 5px;
  bottom: 50px;
  border-radius: 4px;
  overflow: hidden;
  a {
    width: 100%;
    img {
      width: 100%;
    }
  }
  .close {
    position: absolute;
    right: 4px;
    top: 4px;
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
}
